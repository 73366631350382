$(function() {

	toastr.options = {
		closeButton: true,
		progressBar: true,
		positionClass: 'toast-top-right',
		preventDuplicates: true,
	};

	if(typeof mensagem != 'undefined'){

		toastr[tipoMensagem](mensagem);
	}
});